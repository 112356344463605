export const publicRoutes = [
  {
    path: '/',
    name: 'auth',
    component: () => import('~/layouts/AuthLayout.vue'),
    children: [
      {
        path: '/auth',
        name: 'auth.login',
        component: () => import('~/pages/auth/AuthPage.vue'),
        meta: { public: true, title: 'Авторизация' },
      },
    ],
  },
  {
    path: '/not-found',
    name: 'object-not-found',
    component: () => import('~/pages/ObjectNotFound.vue'),
  },
  {
    path: '/change-password',
    name: 'change.password',
    component: () => import('~/pages/ChangePassword.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('~/pages/PageNotFound.vue'),
  },
];
