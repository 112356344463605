export const privateRoutes = [
  {
    path: '/',
    name: 'app',
    component: () => import('~/layouts/AppLayout.vue'),
    redirect: { name: 'home' },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('~/pages/home/HomePage.vue'),
        meta: { title: 'Главная страница' },
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('~/pages/profile/ProfileIndex.vue'),
        meta: { title: 'Профиль' },
      },
      {
        path: '/colleagues',
        name: 'colleagues',
        component: () => import('~/pages/colleagues/ColleaguesIndex.vue'),
        meta: { title: 'Все коллеги' },
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: () => import('~/pages/notifications/NotificationsIndex.vue'),
        meta: { title: 'История уведомлений' },
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('~/pages/news/NewsIndex.vue'),
        meta: { title: 'Новости' },
      },
      {
        path: '/news/:id',
        name: 'news.detail',
        component: () => import('~/pages/news/NewsDetail.vue'),
        meta: { title: 'Новости' },
      },
      {
        path: '/requests',
        name: 'requests',
        component: () => import('~/layouts/RequestsLayout.vue'),
        meta: { title: 'Запросы' },
        redirect: { name: 'my.requests' },
        children: [
          {
            path: '/my-requests',
            name: 'my.requests',
            component: () => import('~/pages/requests/MyRequestsIndex.vue'),
          },
          {
            path: '/assigned-requests',
            name: 'assigned.requests',
            component: () => import('~/pages/requests/AssignedRequestsIndex.vue'),
          },
        ],
      },
      {
        path: '/surveys',
        name: 'surveys',
        component: () => import('~/pages/surveys/SurveysIndex.vue'),
        meta: { title: 'Опросники' },
      },
      {
        path: '/survey/:id',
        name: 'survey.detail',
        component: () => import('~/pages/surveys/SurveyDetail.vue'),
        meta: { title: 'Список вопросов' },
        props: true,
      },
      {
        path: '/manager',
        name: 'manager.index',
        component: () => import('~/pages/manager/ManagerIndex.vue'),
        meta: { title: 'Менеджер' },
      },
      {
        path: '/manager-monitoring-employee/:id',
        name: 'manager.monitoring.employee',
        component: () => import('~/components/manager/monitoring/ManagerMonitoringEmployee.vue'),
        meta: { title: 'Менеджер' },
        props: true,
      },
      {
        path: '/colleagues/:id',
        name: 'colleagues.detail',
        component: () => import('~/pages/colleagues/ColleaguesDetail.vue'),
        meta: { title: 'Страница коллег' },
        props: true,
      },
      {
        path: '/403',
        name: 'error403',
        component: () => import('~/pages/ForbiddenPage.vue'),
        meta: { title: 'Недостаточно прав' },
      },
      {
        path: '/not-found',
        name: 'object-not-found',
        component: () => import('~/pages/ObjectNotFound.vue'),
      },
      {
        path: '/:pathMatch(.*)',
        name: 'not-found',
        component: () => import('~/pages/PageNotFound.vue'),
      },
    ],
  },
];
