import { app } from '~/app';
// @ts-ignore comment-request
import { AtToastPlugin } from '@packages/aliftech-ui';

app.use(AtToastPlugin);

app.config.globalProperties.$toast = AtToastPlugin;

export const $toast = AtToastPlugin;

app.mount('#app');
