import { apiClient } from '~/shared/api/apiClient';

/**
 * Get base data that used everywhere in app
 * @returns { Promise<AxiosResponse<any>> }
 */
const getApp = params => apiClient.get('/app', { params });

/**
 * Send firebase cloud messaging client token
 * @params {Object}
 * @returns { Promise<AxiosResponse<any>> }
 */
const sendFirebaseTokenRequest = params => apiClient.post('/store-token', params);

export { getApp, sendFirebaseTokenRequest };
