import axios from 'axios';
import { throttleAdapterEnhancer } from 'axios-extensions';
import { parseErrorsFromResponse } from '~/shared/utils/parsers';
import { objCheckType } from '~/shared/utils/computeFunc';
import { useCookies } from 'vue3-cookies';
import router from '~/app/router';
import { $toast } from '~/main';
import { refreshAuthToken } from '~/shared/api/auth.api';

const { cookies } = useCookies();

const throttleConfig = {
  threshold: 1000,
};

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_API}/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  adapter: throttleAdapterEnhancer(axios.defaults.adapter, throttleConfig),
});

const generateToaster = errors => {
  if (errors && objCheckType(errors, 'string')) {
    $toast.show({ title: 'Что-то пошло не так', subTitle: errors, type: 'error' });
  } else if (errors && errors.length) {
    errors.forEach(error => {
      $toast.show({ title: 'Что-то пошло не так', subTitle: error, type: 'error' });
    });
  }
};

const clearCookie = () =>
  cookies
    ? new Promise((resolve, reject) => {
        try {
          cookies.remove('auth');
          resolve();
        } catch (e) {
          reject(e);
        }
      })
    : (() => {
        const error = '[FE]Ошибка, не можем получить доступ к `cookie` на вашем устройстве';
        generateToaster(error);
        return Promise.reject(error);
      })();

const authInterceptor = config => {
  const authToken = cookies?.get('auth');
  if (authToken) {
    config.headers['Authorization'] = authToken;
  }
  return config;
};

let requestPromise = null;

const refreshToken = async error => {
  const request = error.config;
  let response = null;

  try {
    if (requestPromise) {
      response = await requestPromise;
    } else {
      requestPromise = refreshAuthToken();
      response = await requestPromise;
    }
    if (response) {
      const token = response.data?.token_type + ' ' + response.data?.access_token;

      setToken(token);
      return apiClient(request);
    }
  } catch (e) {
    cookies.remove('auth');
    router.push({ name: 'auth.login' });
    delete apiClient.defaults.headers.common['Authorization'];
  } finally {
    requestPromise = null;
  }
  return null;
};

const unAuthenticate = () => {
  delete apiClient.defaults.headers['Authorization'];
  cookies.remove('auth');
  router.push({ name: 'auth.login' });
};

const errorStatus = {
  401: async error => {
    if (error.response.data.code === 'token_expired') {
      return refreshToken(error);
    } else {
      await unAuthenticate();
    }
  },
  403: function () {
    router.push({ name: 'error403' });
  },
  419: function () {
    router.back() || router.push('/');
  },
};

const errorInterceptor = async error => {
  let errors = parseErrorsFromResponse(error);
  if (error.response.status in errorStatus) {
    const responseWithRefreshedToken = await errorStatus[error.response.status](error);
    if (responseWithRefreshedToken) {
      return Promise.resolve(responseWithRefreshedToken);
    }
    errorStatus[error.response.status]();
  } else {
    generateToaster(errors);
  }
  return Promise.reject(error);
};

const responseInterceptor = response => response;

apiClient.interceptors.response.use(responseInterceptor, errorInterceptor);
apiClient.interceptors.request.use(authInterceptor);

export const setToken = function (token) {
  if (apiClient?.defaults?.headers?.common) {
    apiClient.defaults.headers.common['Authorization'] = token;
  } else throw new Error('Ошибка во время установки токена');
  cookies.set('auth', token);
};

export { apiClient, clearCookie };
