import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import loaderDirectives from '~/shared/directives/loaderDirectives';
import ViewerDirective from '~/shared/directives/ViewerDirective';
import { finder } from '~/shared/utils/filters/finder';
import '~/app/plugins/firebaseSetEnviroments';
import 'viewerjs/dist/viewer.css';
import '~/shared/assets/css/main.css';

import {
  formatDateByDayMonthYear,
  formatDistanceDate,
  readableDate,
  readableMonth,
  readableTime,
} from '~/shared/utils/filters/dateFilters';

export const app = createApp(App);
app.config.globalProperties.$filters = {
  formatDateByDayMonthYear,
  formatDistanceDate,
  readableDate,
  readableTime,
  readableMonth,
  finder,
};
app.directive('viewer', ViewerDirective);
app.directive('img-loader', loaderDirectives);

app.use(router);

app.use(createPinia());
