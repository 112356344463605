import { createRouter, createWebHistory } from 'vue-router';
import { publicRoutes } from '~/app/router/public';
import { privateRoutes } from '~/app/router/private';
import { useCookies } from 'vue3-cookies';
import { deleteToken, getMessaging, getToken } from 'firebase/messaging';
import { parseErrorsFromResponse } from '~/shared/utils/parsers';
import { $toast } from '~/main';

const { cookies } = useCookies();
import { authModel } from '~/entities/auth';
import { appModel } from '~/entities/app';

const router = createRouter({
  history: createWebHistory(),
  routes: privateRoutes.concat(publicRoutes),
});

router.beforeEach(async (to, from, next) => {
  const authStore = authModel.useStore();
  const appStore = appModel.useStore();
  document.title = 'Team' + (to?.meta?.title ? ' - ' + to.meta.title : '');
  const loggedIn = cookies.get('auth');
  if (!loggedIn && to.name !== 'auth.login' && to.name !== 'change.password') {
    next({ name: 'auth.login', query: { from: to.fullPath } });
    return;
  }
  if ('Notification' in window) {
    if (Notification.permission !== 'denied' && !loggedIn) {
      const messaging = getMessaging();
      getToken(messaging, {
        vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
      }).then(currentToken => {
        if (currentToken) {
          deleteToken(messaging);
        }
      });
    }
  }
  if (loggedIn && to.name === 'auth.login') {
    next(from);
  } else {
    if (!authStore.user && loggedIn) {
      await authStore.getUserFunc().catch(error => {
        $toast.show({ title: 'Ошибка', subTitle: parseErrorsFromResponse(error), type: 'error' });
      });
      if (!appStore.globals) {
        await appStore.getAppFunc().catch(error => {
          $toast.show({ title: 'Ошибка', subTitle: parseErrorsFromResponse(error), type: 'error' });
        });
      }
    }
  }

  next();
});

router.onError(error => {
  if (/loading chunk (.*)? failed./i.test(error.message)) {
    alert('Возможно версия сайта устарела. Сейчас эта страница будет обновлена.');
    window.location.reload();
  }
});

export default router;
