import { apiClient, clearCookie } from '~/shared/api/apiClient';
import { objCheckType } from '~/shared/utils/computeFunc';
import router from '~/app/router';
import { useCookies } from 'vue3-cookies';

const END_POINT = '/auth';
const { cookies } = useCookies();

/**
 * Set authentication setting after login
 * @param {Object} res
 * @returns {*}
 */
const setAuth = res => {
  const data = res?.data;
  const accessToken = data?.access_token;
  const tokenType = data?.token_type;
  const token = tokenType + ' ' + accessToken;

  if (objCheckType(accessToken, 'string') && objCheckType(tokenType, 'string')) {
    if (cookies) {
      clearCookie().finally(() => {
        cookies.set('auth', token);
      });
      apiClient.defaults.headers['Authorization'] = token;
    }
  }
  return res;
};

/**
 * Set logout user
 * @returns { Promise<AxiosResponse<any>> }
 */
const getUser = () => apiClient.get(`/me`);

/**
 * Set authorization login user
 * @param {Object} params - Request params
 * @returns {Promise<*>}
 */
const login = params =>
  apiClient
    .post(`${END_POINT}/login-password`, { remember_me: true, application: 'TEAM', ...params })
    .then(response => {
      setAuth(response, params.remember_me);
    });

/**
 * Set logout user
 * @returns { Promise<AxiosResponse<any>> }
 */
const logout = () =>
  apiClient.post(`${END_POINT}/logout?app=Client`).then(() => {
    clearCookie().finally(() => {
      cookies.remove('auth');
      delete apiClient.defaults.headers['Authorization'];
      router.push({ name: 'auth' }).finally(() => {});
    });
  });

/**
 * Send Application Refresh Token
 * @params {String} - application name
 * @returns { Promise<AxiosResponse<any>> }
 */

const refreshAuthToken = () =>
  apiClient.post(`${END_POINT}/refresh`, {
    application: 'TEAM',
  });

export { login, logout, getUser, refreshAuthToken };
